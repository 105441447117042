import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { formatAsCurrency } from '../../../../utils'
import { HydraTable, Heading, TextFilter, DateRangeFilter, SelectFilter, TimeAgo } from '@peracto/peracto-ui'

import { useConfig } from '@peracto/peracto-config'
import { GET_LIST, useClient } from '@peracto/client'

const STATUS_VALUES = [
    { value: 'complete', label: 'Complete' },
    { value: 'successful', label: 'Successful' },
    { value: 'dispatched', label: 'Dispatched' },
    { value: 'pending', label: 'Pending' },
    { value: 'processing', label: 'Processing' },
    { value: 'failed', label: 'Failed' },
    { value: 'retrying', label: 'Retrying' },
    { value: 'cancelled', label: 'Cancelled' },
]

const TRANSFER_STATUS_VALUES = [
    { value: 'successful', label: 'Transfered Successfully' },
    { value: 'failed', label: 'Transfer Failed' },
    { value: 'pending', label: 'Pending' },
    { value: 'retrying', label: 'Retrying' },
]

export const OrdersList = () => {
    // Default visible columns - key = column accessor
    const [selectedColumns, onSelectColumn] = useState({
        'billingAddress.surname': false,
        'deliveryAddress.postalCode': false,
    })

    const { client } = useClient()

    const config = useConfig()
    const formatDate = config.get('user_format_date', config.get('format_date'))

    // The 'selectable' property is used to define if the
    // column is available in the 'manage columns' dropdown
    const columns = useMemo(
        () => [
            {
                Header: 'Order Reference',
                accessor: 'reference',
                sortType: 'basic',
                Filter: (props) => <TextFilter label="Order Reference" {...props} />,
                Cell: (row) => <Link to={row.row.original.id}>{row.cell.value || '-'}</Link>,
                selectable: false,
            },
            {
                Header: 'Customer Order Reference',
                accessor: 'orderReference',
                sortType: 'basic',
                Filter: (props) => <TextFilter label="Customer Order Reference" {...props} />,
                Cell: (row) => <Link to={row.row.original.id}>{row.cell.value || '-'}</Link>,
                selectable: false,
            },
            {
                Header: 'Email',
                accessor: 'email',
                sortType: 'basic',
                Filter: (props) => <TextFilter label="Email" {...props} />,
                selectable: false,
            },
            {
                Header: 'Billing Surname',
                accessor: 'billingAddress.surname',
                sortType: 'basic',
                Filter: (props) => <TextFilter label="Billing Surname" {...props} />,
                show: selectedColumns['billingAddress.surname'],
                selectable: true,
            },
            {
                Header: 'Delivery Surname',
                accessor: 'deliveryAddress.surname',
                sortType: 'basic',
                Filter: (props) => <TextFilter label="Delivery Surname" {...props} />,
                selectable: false,
            },
            {
                Header: 'Delivery Postcode',
                accessor: 'deliveryAddress.postalCode',
                sortType: 'basic',
                Filter: (props) => <TextFilter label="Delivery Postcode" {...props} />,
                show: selectedColumns['deliveryAddress.postalCode'],
                selectable: true,
            },
            {
                Header: 'Total (inc. tax)',
                accessor: 'totalIncTaxAfterDiscount',
                sortType: 'basic',
                Filter: (props) => <TextFilter type="number" label="Total (inc. tax)" {...props} />,
                Cell: (row) => formatAsCurrency(row.cell.value, row?.cell?.row?.original?.currency),
                selectable: false,
            },
            {
                Header: 'Total (exc. tax)',
                accessor: 'totalExcTaxAfterDiscount',
                sortType: 'basic',
                Filter: (props) => <TextFilter type="number" label="Total (exc. tax)" {...props} />,
                Cell: (row) => formatAsCurrency(row.cell.value, row?.cell?.row?.original?.currency),
                selectable: false,
            },
            {
                Header: 'Status',
                accessor: 'status',
                sortType: 'basic',
                Filter: (props) => (
                    <SelectFilter
                        label="Status"
                        options={[{ value: '', label: 'Please select...' }, ...STATUS_VALUES]}
                        {...props}
                    />
                ),
                Cell: (row) => (
                    <span className="text-capitalize">
                        {STATUS_VALUES.find((s) => s.value === row.cell.value).label}
                    </span>
                ),
                selectable: false,
            },
            {
                Header: 'Transfer Status',
                accessor: 'transferStatus',
                sortType: 'basic',
                Filter: (props) => (
                    <SelectFilter
                        label="Transfer Status"
                        options={[{ value: '', label: 'Please select...' }, ...TRANSFER_STATUS_VALUES]}
                        {...props}
                    />
                ),
                Cell: (row) => (
                    <span className="text-capitalize">
                        {TRANSFER_STATUS_VALUES.find((s) => s.value === row.cell.value).label}
                    </span>
                ),
                selectable: false,
            },
            {
                Header: 'Order Created',
                accessor: 'createdAt',
                Filter: (props) => <DateRangeFilter label="Order Created" {...props} />,
                Cell: ({ cell: { value } }) =>
                    value ? (
                        <TimeAgo title={formatDate(new Date(value))} date={value} />
                    ) : (
                        '-'
                    ),
                selectable: false,
            },
        ],
        [formatDate, selectedColumns],
    )

    const onFetchData = (options) => client(GET_LIST, 'orders', options)

    return (
        <>
            <Helmet>
                <title>Orders | Peracto</title>
            </Helmet>
            <Heading name="Orders" />

            <div className="row">
                <div className="col">
                    <div className="card card--transparent">
                        <div className="card-body">
                            <HydraTable
                                columns={columns}
                                selectedColumns={selectedColumns}
                                onFetchData={onFetchData}
                                onSelectColumn={onSelectColumn}
                                defaultFilters={{
                                    reference: '',
                                    email: '',
                                    status: '',
                                    createdAt: '',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrdersList
