const { darken, lighten } = require('polished')

// Used so hover colors get automatically updated
const brandPrimaryColor = '#5DBA23'
const brandSecondaryColor = '#4A4A4A'
const brandBlackColor = '#222222'
const brandWhiteColor = '#FFFFFF'

const colors = {
    // Base colors
    'brand-primary': brandPrimaryColor,
    'brand-secondary': brandSecondaryColor,
    'brand-success': '#EEF7E8',
    'brand-danger': '#C20016',
    'brand-warning': '#FDF1F2',
    'brand-pale-danger': '#FCF2F3',
    'brand-white': brandWhiteColor,
    'brand-light-grey': '#F2F2F2',
    'brand-grey': '#D8D8D8',
    'brand-silver': '#e2e8f0',
    'brand-dark-grey': '#6F6F6F',
    'brand-black': brandBlackColor,

    // Hover colors
    'brand-primary-lighten': lighten(0.05, brandPrimaryColor),
    'brand-primary-darken': darken(0.05, brandPrimaryColor),

    'brand-secondary-lighten': lighten(0.05, brandSecondaryColor),
    'brand-secondary-darken': darken(0.05, brandSecondaryColor),

    'brand-black-lighten': lighten(0.05, brandBlackColor),
    'brand-black-darken': darken(0.05, brandBlackColor),

    'brand-white-lighten': lighten(0.05, brandWhiteColor),
    'brand-white-darken': darken(0.05, brandWhiteColor),
}

const fonts = {
    heading: ['Mulish, sans-serif'],
    body: ['Mulish, sans-serif'],
}

module.exports = { colors, fonts }
