import { theme } from '@chakra-ui/core'
import { css } from '@emotion/core'

import { fonts, colors } from './tailwind'

// [container, breakpoint]
const sizes = {
    sm: ['520px', '576px'],
    md: ['720px', '768px'],
    lg: ['960px', '992px'],
    xl: ['1170px', '1200px'],
}

const breakpoints = Object.values(sizes).reduce((bp, size) => {
    bp.push(size[1])
    return bp
}, [])

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

export const EditorTheme = {
    ...theme,
    breakpoints,
    colors: {
        ...theme.colors,
    },
    fonts: {
        ...theme.fonts,
        heading: 'Mulish, sans-serif',
        body: 'Mulish, sans-serif',
    },
}

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 992,
        desktop: 1024,
        desktopL: 1700,
    },
}

// Global styles designs: link to the client's style guide if there is one.

export const GlobalStyles = css`
    /* HEADING AND TEXT */
    body {
        font-family: ${fonts.body};
        color: ${colors['brand-black']};
    }

    /* ADMIN CONTENT BLOCK */
    .ory-row {
        h1 {
            font-family: ${fonts.heading};
            font-size: 40px;
            font-weight: 500;
            line-height: 48px;
        }

        h2 {
            font-family: ${fonts.heading};
            font-size: 24px;
            font-weight: 500;
            line-height: 30px;
            margin-bottom: 15px;
        }

        h3 {
            font-family: ${fonts.heading};
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
        }

        h4 {
            font-family: ${fonts.heading};
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
        }

        h5 {
            font-family: ${fonts.heading};
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;

        h6 {
            font-family: ${fonts.heading};
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
        }

        p {
            font-family: ${fonts.body};
             font-size: 16px;
            line-height: 24px;
        }

        strong {
            font-weight: 700;
        }

        .text-tiny {
            font-family: ${fonts.body};
            font-size: 10px;
            line-height: 16px;
        }

        .text-small {
            font-family: ${fonts.body};
            font-size: 12px;
            line-height: 18px;
        }

        .text-big {
            font-size: 24px;
            line-height: 30px;
        }

        .text-huge {
            font-size: 1.8em;
        }

        blockquote {
            font-style: normal;
            font-size: 16px;
            line-height: 24px;
            padding-left: 30px;
            border-left: 4px solid ${colors['brand-primary']};
        }

        hr {
            background: ${colors['brand-primary']};
            height: 2px;
            margin: 15px 0;
        }

        input {
            color: ${colors['brand-dark-grey']};
            border-color: ${colors['brand-grey']};
            border-radius: 64px;
            border-width: 1px;
            font-size: 16px;

            &:hover {
                border-color: ${colors['brand-primary']} !important;
            }
            &:focus {
                outline: none;
                border-color: ${colors['brand-primary']} !important;
            }
        }

        textarea {
            color: ${colors['brand-dark-grey']};
            border-color: ${colors['brand-grey']};
            border-radius: 64px;
            border-width: 1px;
            font-size: 16px;

            &:hover {
                border-color: ${colors['brand-primary']} !important;
            }
            &:focus {
                outline: none;
                border-color: ${colors['brand-primary']} !important;
            }
        }

        select {
            color: ${colors['brand-dark-grey']};
            border-color: ${colors['brand-grey']};
            border-radius: 64px;
            border-width: 1px;
            height: 45px;
            font-weight: 400;

            &:hover {
                background: ${colors['brand-primary']} !important;
            }
            &:focus {
                outline: none;
                border-color: ${colors['brand-primary']} !important;
            }

            + div {
                svg {
                    color: ${colors['brand-black']};
                }
            }
        }

        input[type='checkbox'] {
            + div {
                border-color: ${colors['brand-dark-grey']} !important;
                border: 1px solid;

                &:hover {
                    border-color: ${colors['brand-primary']} !important;
                }
            }

            &:checked {
                + div {
                    background-color: ${colors['brand-primary']};
                    border-color: ${colors['brand-secondary']};
                }
            }
        }

        input[type='radio'] {
            + div {
                border-color: ${colors['brand-dark-grey']} !important;
                border-width: 1px !important;
            }

            &:hover {
                + div {
                    border-color: ${colors['brand-primary']} !important;
                }
            }

            &:checked {
                + div {
                    background-color: ${colors['brand-primary']} !important;
                    border-color: ${colors['brand-primary']} !important;
                    box-shadow: inset 0px 0px 0px 2px ${colors['brand-white']} !important;

                    span {
                        display: none;
                    }
                }
            }
        }

        /* LIST */
        ul:not([class^='DayPicker']) {
            list-style: none;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 20px;
            position: relative;

            li {
                line-height: 24px;
                padding-left: 20px;
                position: relative;
                margin-bottom: 5px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &:before {
                    display: block;
                    position: absolute;
                    left: 0;
                    height: 8px;
                    width: 8px;
                    border-radius: 9999px;
                    background-color: ${colors['brand-primary']};
                    content: '';
                    top: 12px;
                    margin-right: 10px;
                }
            }
        }

        ol:not(.breadcrumb) {
            list-style: none;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 20px;
            position: relative;
            padding-left: 0;
            counter-reset: list-number;
            font-size: 16px;
            line-height: 24px;

            li {
                margin-bottom: 15px;
                position: relative;
                display: flex;
                counter-increment: list-number;
                &:before {
                    display: block;
                    content: '';
                    margin-right: 5px;
                    content: counter(list-number) '.';
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        /* TABLE */
        .table {
            td {
                vertical-align: top;
                padding: 15px;
            }
        }

        .js-focus-visible :focus:not([data-focus-visible-added]) {
            outline: none;
            box-shadow: none;
        }

        a:not([type^='button']) {
            text-decoration: underline;
            color: ${colors['brand-primary']};

            &:hover {
                color: ${colors['brand-primary-darken']};
            }

            &:visited {
                color: #3f05c2;
            }
        }

        a[type^='button'] {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        form {
            textarea,
            input {
                margin-bottom: 15px;
            }
        }

        .content__text {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin-bottom: 10px;
            }
        }

        figure.table {
            width: 100%;

            table {
                margin: 15px 0;
                min-width: 100%;
                font-size: 14px;
                line-height: 20px;

                tbody {
                    > tr {
                        &:first-of-type {
                            font-weight: bold;
                            font-size: 16px;
                            background-color: ${colors['brand-black']} !important;
                            color: ${colors['brand-white']};
                            border-radius: 13px 13px 0 0;
                            border-top: none;

                            td {
                                border-top: none;

                                &:first-of-type {
                                    border-top-left-radius: 13px;
                                }

                                &:last-of-type {
                                    border-top-right-radius: 13px;
                                }
                            }
                        }

                        &:last-of-type {
                            border-radius: 0 0 13px 13px;
                            border-bottom: none;

                            td {
                                border-bottom: none;

                                &:first-of-type {
                                    border-bottom-left-radius: 13px;
                                }

                                &:last-of-type {
                                    border-bottom-right-radius: 13px;
                                }
                            }
                        }

                        &:nth-child(odd) {
                            background-color: ${colors['brand-light-grey']};
                        }

                        &:nth-child(even) {
                            background-color: transparent;
                        }
                    }

                    tr {
                        td {
                            padding: 15px;
                        }
                    }
                }
            }
        }
    }
`
