const sharedClasses =
    'flex items-center justify-center p-[1em] text-[14px] rounded-[23px] leading-[20px] h-[42px] no-underline uppercase w-full font-semibold transition-color duration-200 disabled:cursor-not-allowed disabled:opacity-30'

export const primary = {
    solidClasses: `${sharedClasses} bg-brand-primary text-brand-white hover:bg-brand-primary-darken hover:text-brand-white`,
    outlineClasses: `${sharedClasses} bg-none text-brand-primary border border-brand-primary hover:bg-brand-primary hover:text-brand-white active:bg-brand-primary`,
}

export const secondary = {
    solidClasses: `${sharedClasses} bg-brand-secondary text-brand-white hover:bg-brand-secondary-darken hover:text-brand-white`,
    outlineClasses: `${sharedClasses} bg-none text-brand-secondary border border-brand-secondary hover:bg-brand-light-grey active:bg-brand-secondary`,
}
