import React from 'react'
import { ThemeProvider } from 'styled-components'

import Peracto from '@peracto/peracto'
import Config from '@peracto/peracto-config'
import PeractoUser from '@peracto/peracto-user'
import PeractoCatalogue from '@peracto/peracto-catalogue'
import PeractoCommon from '@peracto/peracto-common'
import PeractoContent from '@peracto/peracto-content'
import PeractoLocations from '@peracto/peracto-locations'
import PeractoPromotions from '@peracto/peracto-promotions'

import { EditorTheme, AdminTheme, GlobalStyles } from './theme'
import * as buttonVariants from './theme/buttonVariants'

import { colors, fonts } from './theme/tailwind'

import blocks from './blocks'
import { OrdersList, OrdersView } from './packages/Catalogue/Orders'

import compact from 'lodash/compact'
import startCase from 'lodash/startCase'

const config = new Config({
    api: process.env.REACT_APP_API,
    algolia: {
        applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
        searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
        indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
    },
    modules: [
        PeractoUser(),
        PeractoCommon(),
        PeractoCatalogue({ OrdersList, OrdersView }),
        PeractoLocations(),
        PeractoPromotions(),
        PeractoContent(),
    ],
    editorConfig: {
        theme: EditorTheme,
        colorOptions: compact(
            Object.entries(colors).map(([label, value]) => {
                return {
                    label: startCase(label.replaceAll('brand-', '')),
                    value,
                }
            }),
        ),
        fonts,
        locales: ['en_GB', 'es_ES', 'fr_FR'],
        blocks,
        isTailwind: true,
        buttonVariants,
        inputTypes: {},
        stylesheets: ['https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap'],
        globalStyles: GlobalStyles,
    },
    features: {
        dashboard: {
            tables: false,
        },
        shippingServices: {
            deliveryDays: true,
        },
    },
})

const App = () => (
    <ThemeProvider theme={AdminTheme}>
        <Peracto config={config} />
    </ThemeProvider>
)

export default App
