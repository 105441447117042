import {
    Accordion,
    Carousel,
    Container,
    ProductsCarousel,
    MenuItemsCarousel,
} from '@peracto/peracto-blocks-tailwind'

export default {
    plugins: {
        content: [Carousel, Accordion, ProductsCarousel, MenuItemsCarousel],
        layout: [Container],
    },
}
